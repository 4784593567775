import React from "react";

import "./Loading.css";

const Loading = () => {
  return (
    <div id="bars">
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
      <div
        className="bar"
        style={{ animationDuration: `${Math.random() * 300 + 400}ms` }}
      ></div>
    </div>
  );
};

export default Loading;
