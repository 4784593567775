import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// import Dashboard from "./pages/Dashboard/Dashboard";
// import Playlist from "./pages/Playlist/Playlist";
import Home from "./pages/Home/Home";
// import Callback from "./pages/Callback/Callback";
// import Track from "./pages/Track/Track";
import NavBar from "./components/UI/NavBar/NavBar";
import Cookies from "js-cookie";
import Loading from "./components/UI/Loading/Loading";
import { authenticated } from "./utils";
import ScrollBox from "./components/UI/ScrollBox";

const Dashboard = React.lazy(() => import("./pages/Dashboard/Dashboard"));
const Playlist = React.lazy(() => import("./pages/Playlist/Playlist"));
// const Home = React.lazy(() => import("./pages/Home/Home"))
const Callback = React.lazy(() => import("./pages/Callback/Callback"));
const Track = React.lazy(() => import("./pages/Track/Track"));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(authenticated());
  const [currentPage, setCurrentPage] = useState("home");

  // allow children to set state of page
  const setCurrentPageHome = () => {
    setCurrentPage("home");
  };

  const setCurrentPageDashboard = () => {
    setCurrentPage("dashboard");
  };

  const setCurrentPagePlaylist = () => {
    setCurrentPage("playlist");
  };

  const setCurrentPageTrack = () => {
    setCurrentPage("track");
  };

  const setCurrentPageArtist = () => {
    setCurrentPage("artist");
  };

  // allow children to log in/out
  const logOut = () => {
    localStorage.removeItem("access_token")
    localStorage.removeItem("refresh_token")
    console.log(localStorage.getItem("access_token"))
    setIsAuthenticated(false);
  };

  const logIn = () => {
    setIsAuthenticated(true);
  };

  return (
    <Router>
      <NavBar
        isAuthenticated={isAuthenticated}
        currentPage={currentPage}
        logOut={logOut}
      />
      <Suspense fallback={<div><Loading /></div>}>
        <Routes>
          <Route
            path="/dashboard"
            element={<Dashboard setCurrentPage={setCurrentPageDashboard} />}
          />
          <Route
            path="/playlist/:pid"
            element={<Playlist setCurrentPage={setCurrentPagePlaylist} />}
          />
          <Route
            path="/track/:tid"
            element={<Track setCurrentPage={setCurrentPageTrack} />}
          />
          <Route
            path="/"
            element={
              <Home
                isAuthenticated={isAuthenticated}
                setCurrentPage={setCurrentPageHome}
                logOut={logOut}
              />
            }
          />
          <Route path="/callback" element={<Callback logIn={logIn} />} />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
