import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { authenticated } from "../../../utils";
import "./NavBar.css";

const NavBar = (props) => {
  const onClickLogIn = () => {
    const clientId = "a0e467b95cc74ed486c3098f85aabad6";
    const redirectUri = `${process.env.REACT_APP_BASE_URL}/callback`;
    const state = Math.random().toString(36).slice(2);
    const scope = [
      "playlist-read-private",
      "playlist-read-collaborative",
      "user-top-read",
      "user-read-recently-played",
    ].join(" ");
    window.open(
      `https://accounts.spotify.com/authorize?response_type=code&client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&state=${state}&show_dialog=true`,
      "_self"
    );
  };

  const navigate = useNavigate();

  const onClickShuffle = () => {
    navigate("/");
  };

  const onClickDashboard = () => {
    navigate("/dashboard");
  };

  const onClickLogOut = () => {
    props.logOut();
    navigate("/");
  };

  return (
    <nav>
      {/* shuffle */}
      <ul className="nav-bar__list">
        <li className="nav-bar__item" onClick={onClickShuffle}>
          <button>Shuffle</button>
        </li>
        <li className="nav-bar__item" />

        {/* log in (not authenticated) */}
        {!props.isAuthenticated && (
          <li className="nav-bar__item nav-bar__log-in-item" onClick={onClickLogIn}>
            <img
              className="nav-bar__log-in-img"
              src="../../../images/spotify_logo.png"
              alt="Spotify"
            />
            <button>Log In</button>
          </li>
        )}

        {/* dashboard (authenticated) */}
        {props.isAuthenticated && (
          <li
            className={`nav-bar__item  ${
              props.currentPage === "dashboard" ? "nav-bar__item-selected" : ""
            }`}
            onClick={onClickDashboard}
          >
            <button>Dashboard</button>
          </li>
        )}
        {/* log out (authenticated) */}
        {props.isAuthenticated && (
          <li className="nav-bar__item" onClick={onClickLogOut}>
            <button>Log Out</button>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;
