import React, { useEffect } from "react";
import FaqView from "../../components/UI/Faq/FaqView";
import "./Home.css";

const Home = (props) => {
  useEffect(() => {
    props.setCurrentPage();
  }, []);

  return (
    <React.Fragment>
      <div className="home__content">
        <div className="home__grid">
          <div className="home__text home__text-left">
            <h1 className="home__title">Shuffle</h1>
            <h2 className="home__subtitle ">Analyze your Spotify listening</h2>
          </div>
          <img
            className="home__image home__image-right"
            src="../../images/dashboard.png"
          />
        </div>
        <div className="home__grid">
          <img
            className="home__image home__image-left"
            src="../../images/playlist.png"
          />
          <div className="home__text home__text-right">
            <h1 className="home__title">Analyze Playlists</h1>
            <h2 className="home__subtitle ">
              Gain insight on your favorite playlists
            </h2>
          </div>
        </div>
        <div className="home__grid">
          <div className="home__text home__text-left">
            <h1 className="home__title">Analyze Tracks</h1>
            <h2 className="home__subtitle ">
              Learn more about your favorite tracks
            </h2>
          </div>
          <img
            className="home__image home__image-right"
            src="../../images/track.png"
          />
        </div>
        {/* <FaqView /> */}
      </div>
    </React.Fragment>
  );
};

export default Home;
